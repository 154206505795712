import { INavigationItem } from "./types"
import { lazy } from "react"
const Home = lazy(() => import("../pages/Home"))
const About = lazy(() => import("../pages/AboutUs"))
const LearnMusic = lazy(() => import("../pages/LearnMusic"))
const Community = lazy(() => import("../pages/Community"))
const ProjectsAndEvents = lazy(() => import("../pages/ProjectsAndEvents"))
const Donate = lazy(() => import("../pages/Donation"))
const ReferAFriend = lazy(() => import("../pages/ReferAFriend"))
const ArtChallenge = lazy(() => import("../pages/ArtChallenge"))

const websiteNav:INavigationItem[] = [
    {
        title: 'Home',
        url: '/',
        component: Home
    },
    {
        title: 'About us',
        url: '/about',
        component: About

    },
    {
        title: 'Learn Music',
        url: '/learn',
        component: LearnMusic
    },
    {
        title: 'Projects & Events',
        url: '/projects',
        component: ProjectsAndEvents
    },
    {
        title: 'Community',
        url: '/community',
        component: Community
    },
    {
        title: 'Donate',
        url: '/donate',
        component: Donate
    },
    {
        title: 'Refer a Friend',
        url: '/refer',
        component: ReferAFriend,
        isNotLink: true
    },
    {
        title: "Art Challenge",
        url: "/art-challenge",
        component: ArtChallenge,
        isNotLink: true
    }

]

export default websiteNav